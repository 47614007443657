import React from 'react';

const Services2 = (props) => {
    return (
        <section className="shop">
			<div className="container">
				<div className="row row--main-shop">
					<div className="col-lg-3 col-md-4 col-sm-5 col-xs-6">
						<aside className="shop-aside">
							<div className="shop-aside__block wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
								<h2 className="shop-title">Categories<span className="line line--title line--shop"><span className="line__first"></span><span className="line__second"></span></span></h2>
								<ul className="triangle-list triangle-list--shop">
                                    {props.services.map((item, index) => <li key={index} className="triangle triangle-list__one"><a href={`#${item.link}`} className="no-decoration" onClick={() => {
										props.onChangeService(item)
									}}>{item.name}</a></li>)}
								</ul>
							</div>
						</aside>
					</div>
					<div className="col-lg-9 col-md-8 col-sm-7 col-xs-6">
						<div className="shop-main">
							<header className="shop-main__head clearfix wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
								<h2 className="pull-left">{props.currentService.name}</h2>
							</header>
							<div className="items">
								<div className="row">
									<div className="col-xs-12 wow fadeInLeft" data-wow-delay="0.7s" data-wow-duration="1.5s">
										{
											'apparel' === props.currentService.link && (
												<div>
													<img src="images/products/hoodies_tn.jpg" alt="Hoodies" />
													<a  href="images/products/tshirts-black.jpg"><img src="images/products/tshirts-black_tn.jpg" alt="T-shirts" /></a>
												</div>
											)
										}
										{
											'1320-drag-leathers' === props.currentService.link && (
												<div>
    <p> <strong>SPECIFICATIONS AND FEATURES</strong></p>
<p>- Two piece drag racing leathers with 360 internal hidden waist zipper to give one piece protection and no suit separation</p>
<p>- Made from premium Grade A cowhide of 1.3 to 1.4 thickness that has been processed to rival the weight and strength of kangaroo hide ( kangaroo hide available and pricing to be determined upon request)</p>
<p>- Quadruple stitched kevlar reinforced seams</p>

<p>
	- Kevlar backed armour in the shoulders, elbow and knees
</p>

<p>
	- Knox CE armour in shoulders, chest and elbows with tempura liquid protection ( chest armour may be removed at discretion of rider)
</p>
<p>
- Ce approved armour with tempura liquid protection in knees and elbow</p>

<p>- Neoprene and leather collar for maximum comfort in launch and tuck position</p>

<p>- Fully removable and washable lining</p>

<p>-Schoeller  Keprotec Kevlar stretch panel behind the knee and lower leg with no behind the knee seams for maximum comfort and flexibility</p>

<p>- Professional perforation at customer selected areas</p>

<p>- Back protector pocket ready to receive the rider's protector. ( custom removable protector available for purchase upon request)</p>

<p>- Choice of 12 colours and accent colours ( leather swatch sample to be provided to you) and accent colours</p>

<p>- Additional $90-$100 for leather names, numbers, logos on suit</p>

<p>- Custom made suit based upon 24 customer measurements ensure a solution for even the most hard to size rider. No more off the rack disappointments</p>

<p>- Schoeller  Keprotec available in the crotch area for additional comfort</p>

<p>- <strong>$799</strong> without the custom lettering.</p>

<p><strong>MEASUREMENT GUIDE</strong> - <a href="products/Measure.doc">Click Here</a> </p>

<img src="images/products/leather1.jpg" alt="leather1" />
<img src="images/products/leather2.jpg" alt="leather2"/>
<img src="images/products/leather3.jpg" alt="leather3"/>
 <p><strong>E-mail </strong><a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="logo"/></a><strong> for more info or call us at {props.phoneNum}</strong></p>
												</div>
											)
										}
										{
											'parts-replacement' === props.currentService.link && (
												<div>
													<p>Download PDF Parts List Here: <a href="products/Parts.pdf">PARTS</a></p>
												</div>
											)
										}
										{
											'tires' === props.currentService.link && (
												<div>
												<p><a href="http://www.motorcycle-karttires.com/"><img  src="images/bridgestone.gif" alt="bridgestone"/></a></p>
												<p><a href="http://www.teammichelin.com/" ><img  src="images/michelin.gif" alt="michelin"/></a></p>
												<p><a href="http://www.dunlopmotorcycle.com/">
												<img  src="images/dunlop.gif" alt="dunlop"/></a></p>
												<p><a href="http://www.pirelli.com/">
												<img src="images/Pirelli.jpg" alt="pirelli"/></a></p>
												<p><a href="http://www.metzeler.com" >
												<img  src="images/metzeler.gif" alt="metzeler"/></a></p>
											
												<p><strong>Tire sales, Installation and Balancing.</strong></p>
												
												
												Contact <a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid"/></a> for pricing and availability.
												</div>
											)
										}
										{
											'electronics' === props.currentService.link && (
												<div>
													 <p>We Install:</p>	
													<ul >
													<li>- Gear indicators </li>
													<li>- Yellow box speedo re-calibrators </li>
													<li>- Rev (shift) lights </li>
													<li>- Strobes </li>
													<li>- TRE modifications (timing retard eliminator) </li>
													<li>- Custom Lighting </li>
													<li>- Electric and Air powershifters </li>

													</ul>
													<br/>
													<p> And much more! </p>
												</div>
											)
										}
										{
											'general-maintenance' === props.currentService.link && (
												<div>
													<p><strong>Contact <a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid"/></a> to book your appointment!</strong></p>
												</div>
											)
										}
										{
											'custom-mods' === props.currentService.link && (
												<div>
													<p>Customize your bike any way you want. If you have something unique you want done to your bike but can't find any one to do it. Call or email us we are capable of doing just about anything you can think of to a bike. From Euro tails, to putting the right engine in the wrong bike, to rebuilding that crashed bike, to modifying your bike for race track or drag race use. Work is 100% guaranteed and we have a strong passion for working on motorcycles! </p>
													
													<p><strong>JUST IN! </strong></p>

													<p>CNC'ed Rear set relocators.  Check out our <a href="/products/HybridRearSets.pdf">info-sheet</a></p>
													
													
													
													<img src="images/hybridracinglogo_small.gif" alt="logo"/>
													<br/>
													<br/>
													<p>CAD/CAM designed custom performance parts and accessories</p>
													<h3>General</h3>
													<ul>
														<li>- License plate relocation mods </li>
														<li>- Frame sliders </li>
														<li>- Rear suspension lowering links </li>
														<li>- Custom motor swaps (GSX-R1000 motor into 750 chassis, etc.) </li>
													</ul>
													<br/>
													<b>Something else in mind?  Let us know! </b> <a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid"/></a>
												</div>
											)
										}
										{
											'bike-transporting' === props.currentService.link && (
												<div>
													<p>Crashed your bike or it just doesn't run and you need your bike transported 
					somewhere safely? Well then we have the solution for you. Safe and 
					reliable bike transportation needs within the city.</p>
                <p> Contact <a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid" /></a> for more info.</p>
												</div>
											)
										}
										{
											'dyno-sheets' === props.currentService.link && (
												<div>
													    <p ><strong>'04 GSX-R1000 stock motor with 60   		shot of nitrous</strong></p>
    <img src="images/jc_200hp_dyno2.jpg" alt="jc_200hp_dyno2" />
	<br/><br/>
  	<p ><strong>'06 Hayabusa stock motor, stock   		exhaust with 60 shot of nitrous</strong></p>
    <img src="images/dustin_220hp_dyno3.jpg" alt="dustin_220hp_dyno3"/>
	<br/><br/>

    <p><strong>'04 CBR600RR stock motor with 20   		shot of nitrous</strong></p>
    <img src="images/600rr_on_nitrous.jpg" alt="600rr on nitrous"/>
												</div>
											)
										}
										{
											'motor-mount-kits' === props.currentService.link && (
												<div>
				<h4>(Patent Pending)</h4>
				<p >Why get rid of your GSX-R600 or 750 for a 1000? Build yourself one! 
				That's right, with these special motor mount kits you can 
				install a GSX-R1000 motor into your GSX-R600 or 750 frame with 
				minor modification. Fool your buddies by making your bike a 
				sleeper with liter bike horsepower!</p>
				<p>All hardware and instructions provided.</p>
				<p>All prices are shipped within Canada and USA (48 States).</p>

				<br/>
				<h4>Currently available for:</h4>
				<p>01-03 GSX-R600 frame to 01-02 GSX-R1000 engine $180 USD</p>
				<p>01-03 GSX-R600 frame to 03-04 GSX-R1000 engine $150 USD</p>
				<p>01-03 GSX-R600 frame to 05-06 GSX-R1000 engine $150 USD</p>
				<p>04-05 GSX-R600 frame to 01-04 GSX-R1000 engine $150 USD</p>
				<p>04-05 GSX-R600 frame to 05-06 GSX-R1000 engine $150 USD</p>
				<p>00-03 GSX-R750 frame to 01-04 GSX-R1000 engine $270 USD</p>
				<p>00-03 GSX-R750 frame to 05-06 GSX-R1000 engine $220 USD</p>
				<p>04-05 GSX-R750 frame to 01-04 GSX-R1000 engine $150 USD</p>
				<p>04-05 GSX-R750 frame to 05-06 GSX-R1000 engine $150 USD</p>
				<p>01-02 GSX-R1000 frame to 05-06 GSX-R1000 engine $260 USD</p>
				<br/>

				<p><strong>E-mail</strong><a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid"/></a><strong>  for more info or call us at {props.phoneNum}</strong></p>
	
												</div>
											)
										}
										{
											'laser-wheel-alignments' === props.currentService.link && (
												<div>    <p>Experienced racers know that proper front to rear wheel alignment is critical to good handling and maximum performance.
												If your bike seems to “prefer” one type of turn over another, it can be a sign that your wheel alignment is not properly set.
												Poor wheel alignment can also lead to wasted horsepower, and pre-mature drive chain and tire wear.</p>
												<p>
												Racing mechanics know that the swingarm wheel alignment marks cannot be trusted to achieve proper alignment. 
												Some racers use calipers to measure the rear wheel axle position, but this method inherently assumes that the frame and swingarm 
												are perfectly straight – they probably are not.</p>
												<p> Another alignment method is to use strings pulled from the rear wheel tangents toward 
												the front wheel, and measure from each string to the front wheel. 
												This method is somewhat more accurate than measuring axel position, but is cumbersome and requires repositioning the strings, 
												and re-measuring as the rear wheel is adjusted.
												The answer to this is doing it the correct way: with a  proper laser wheel alignment device.</p>
												<p> Contact <a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid"/></a> to book your appointment! </p></div>
											)
										}
										{
											'nitrous-oxide' === props.currentService.link && (
												<div>
    <p>Your one stop shop for Nitrous Oxide refills,
    installation, and servicing.</p>
    <br/>
    <p><img align="middle" src="images/products/nos.jpg"  alt="nos"/></p>
    <p> Contact <a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hybrid"/></a> for pricing.</p>
												</div>
											)
										}
										{
											'hybrid-racing-drag-pads' === props.currentService.link && (
												<div>
													<p><strong> <img src="images/products/drag1.gif" alt="drag1"/></strong></p>
															<p><strong> has teamed together with</strong></p>
															<img src="images/products/drag2.gif" alt="drag2"/>
															<br/>
															<p>to develop the exclusive Hybrid Racing Drag Pad designed with the drag racer in mind.   		Working together with TechSpec   		USA, we have come up with these seat   		pads which are designed to be used with aftermarket seat pans. </p>
															<p>They solve a few problems such as not having to sit on the bare fiberglass surface of the seat pan while providing some cushioning and traction for your leathers.   		They will also protect your paintjob from scuffs and scratches!</p>
															<p>Combined with the seat pan, it will allow you to get your center of gravity nice and low to help prevent a wheelie induced run down the track and better your 60-ft's.<br/>
															<br/>
															</p>
															<p>They are 0.25&rdquo; thick total and are 7.25&rdquo; x 13.0&rdquo;. They have foam below the grip surface and   		are reusable! No more seat scuffs and these actually have padding in   		them so you are not taking a beating down the track!</p>
															<p><img src="Images/products/drag12.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag9.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag8.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag7.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag6.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag5.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag4.jpg" alt="drag"/></p>
															<p> <img src="Images/products/drag3.jpg" alt="drag"/></p>
												</div>
											)
										}

									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
    );
};

export default Services2;