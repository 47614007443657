import React from 'react';

const ContactPost = (props) => {
    return (
        <div>
            <div className="blog-page">
			<div className="container">
				<div className="row">
					<div className="col-lg-12 col-md-9 col-sm-8 col-xs-7">
						<div className="blog-main">
							<div className="articles">
								<div className="article">
								<div className="text-center">
									<img src="images/sproket-white.jpg" alt="hybrid racing" width="200px"/>
									<h3>Have any questions? We'd love to hear from you.</h3>
									<h4 >Here's how to get in touch with us:</h4>
									<h3 >Phone: {props.phoneNum}</h3>
									<h3 >Email: <a href="mailto:hybridracingca@hotmail.com"><img src='/images/hybrid.gif' alt='hybridracingca@hotmail.com'/></a></h3>

								</div>
								</div>
							</div>
						</div>
					</div>
					<div className="col-lg-3 col-md-3 col-sm-4 col-xs-5">
						<aside className="blog-aside">
						</aside>
					</div>
				</div>
			</div>
		</div>

        </div>
    );
};

export default ContactPost;