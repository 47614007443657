import React from 'react';

import {Link} from 'react-router-dom'

const Services = (props) => {
    return (
        <section className="services">
        <div className="container">
            <div className="services__main">
                <h2 className="title title--main wow fadeInRight" data-wow-delay="0.7s" data-wow-duration="1.5s"><span className="title__bold">Services</span></h2>
                <div className="row">

                    <div className="col-sm-5 col-xs-12 wow fadeInRight" data-wow-delay="0.7s" data-wow-duration="1.5s">
                        <div className="services__info">
                            {props.services.map((item, index) => 
                            <div key={index} className="services__info-block wow fadeInRight" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                <h5 className="clearfix services__title"><Link className="pull-left no-decoration js-toggle" to={{
                                    pathname: "/services",
                                    hash: `#${item.link}`,
                                    state: {currentService: item}
                                }} onClick={() => {
                                    props.onChangeService(item)
                                }}>{item.name}</Link>
                                <Link className="square square--toggle pull-right js-toggle" to={{
                                    pathname: "/services",
                                }} onClick={() => {
                                    props.onChangeService(item)
                                }}><span className="fa fa-arrow-right"></span></Link></h5>
                            </div>
                            )}
                        </div>
                    </div>

                    <div className="col-sm-7 col-xs-12 wow fadeInLeft" data-wow-delay="0.7s" data-wow-duration="1.5s">
                        <div className="services__img">
                            <img className="img-responsive" src="media/motorcycles/moto-red.jpg" alt="bike" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    );
};

export default Services;