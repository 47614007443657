import React from 'react';

const Reviews = () => {
    return (
        <div className="home-reviews dark-bg">
            <div className="container">
                <div className="row">
                    <div className="motormounts" align="center">
                        <div className="home-reviews__position">
                                <p className="centertext"><a href="mailto:hybridracingca@hotmail.com"><img src="images/hybrid.gif" alt="hyrbid"/></a> motor mount kits are currently available for the following conversions:</p> 
                        </div>
                        <img src="images/motormount_can.jpg" alt="motor mounts" />
                        <div className="home-reviews__author">
                            <ul>
                                <li>01-03 GSX-R600 frame to 01-04 GSX-R1000 engine</li>
                                <li>01-03 GSX-R600 frame to 05-06 GSX-R1000 engine</li>
                                <li>04-05 GSX-R600 frame to 01-04 GSX-R1000 engine</li>
                                <li>04-05 GSX-R600 frame to 05-06 GSX-R1000 engine</li>
                                <li>00-03 GSX-R750 frame to 01-04 GSX-R1000 engine</li>
                                <li>00-03 GSX-R750 frame to 05-06 GSX-R1000 engine</li>
                                <li>04-05 GSX-R750 frame to 01-04 GSX-R1000 engine</li>
                                <li>04-05 GSX-R750 frame to 05-06 GSX-R1000 engine</li>
                                <li>01-02 GSX-R1000 frame to 05-06 GSX-R1000 engine</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Reviews;