import React from 'react';

const LinksPost = (props) => {
    return (
        <div>
            <div className="blog-page">
			<div className="container">
				<div className="row">
					<div className="col-md-9 col-sm-8 col-xs-7">
						<div className="blog-main">
							<div className="articles">
								<div className="article">
                                    <p><a href="http://www.exoticycle.com"><img src="images/gallery/exotic.jpg" alt="exotic"/></a></p>
                                    <p><a href="http://www.roaringtoyz.com"><img src="images/gallery/roaringtoyz.jpg" alt="roaringtoyz"/></a></p>
                                    <p><a href="http://willhiteracing.com/"><img src="images/gallery/willhite.jpg" alt="willhite"/></a></p>
                                    <p><a href="http://www.uponone.com"><img src="images/gallery/uponone.jpg" alt="UpOnOne"/></a></p>
                                    <p><a href="http://www.gixxer.com/"><img src="images/gallery/gixxer.jpg" alt="Gixxer.com"/></a></p>
                                    <p><a href="http://www.graphix2k.com/"><img src="images/gallery/graphix2k.jpg" alt="Graphix2k"/></a></p>

                                    <hr/>
                                    <p>Special Thanks To:</p>
                                    <h4>C&M Metalworks and Welding</h4>
                                    <p>For all your metal fabricating and welding needs contact:
                                    Chubba (613) 852-1096</p>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-4 col-xs-5">
						<aside className="blog-aside">
						</aside>
					</div>
				</div>
			</div>
		</div>

        </div>
    );
};

export default LinksPost;