import React from 'react';

import Header from './Header'
import Sliders from './Sliders'
import Synopsis from './Synopsis'
import Services from './Services'
import MotorMounts from './MotorMounts'
import Partners from './Partners'
import Footer from '../Footer'

export default function HomePage(props) {
    return (
        <div>
            <Header />
            <Sliders />
            <Synopsis/>
            <Services {...props} />
            <MotorMounts/>
            <Partners {...props}/>
            <Footer {...props} /> 
        </div>
    );
}
