import React, { useState } from "react"
import "../../css/animate.css"
import "../../css/blog.css"
import "../../css/bootstrap.css"
import "../../css/colors.css"
import "../../css/custom.css"
import "../../css/doc.css"
import "../../css/font-awesome.min.css"
import "../../css/master.css"
import "../../css/page.css"
import "../../css/responsive.css"
import "../../css/shop.css"
import "../../css/start-page.css"
import "../../css/theme.css"

import { Route } from "react-router-dom"

import HomePage from "./HomePage/HomePage"
import ServicesPage from "./Secondary/ServicesPages/ServicesPage"
import Sponsorship from "./Secondary/Sponsorship/Sponsorship"
import Contact from "./Secondary/Contact/Contact"
import Links from "./Secondary/Links/Links"
import Testimonials from "./Secondary/Testimonials/Testimonials"
import GalleryImages from "./Secondary/GalleryImages/GalleryImages"

const props = {
	phoneNum: "(613) 859 9708",
	copyrightYear: new Date().getFullYear(),
	services: [
		{
			name: "General Maintenance",
			link: "general-maintenance"
		},
		{
			name: "Custom Mods",
			link: "custom-mods"
		},
		{
			name: "Parts Replacement",
			link: "parts-replacement"
		},
		{
			name: "Tires",
			link: "tires"
		},
		{
			name: "Electronics",
			link: "electronics"
		},
		{
			name: "Bike Transporting",
			link: "bike-transporting"
		},
		{
			name: "Dyno Sheets",
			link: "dyno-sheets"
		},
		{
			name: "Motor Mount Kits",
			link: "motor-mount-kits"
		},
		{
			name: "Laser Wheel Alignments",
			link: "laser-wheel-alignments"
		},
		{
			name: "Nitrous Oxide",
			link: "nitrous-oxide"
		},
		{
			name: "Hybrid Racing Drag Pads",
			link: "hybrid-racing-drag-pads"
		},
		{
			name: "Apparel",
			link: "apparel"
		},
		{
			name: "1320 Drag Leathers",
			link: "1320-drag-leathers"
		}
	]
}

function App() {
	const [currentService, setCurrentService] = useState({
		name: "General Maintenance",
		link: "general-maintenance",
		scrollPosition: window.pageYOffset
	})

	const changeService = newService => {
		newService.scrollPosition = window.pageYOffset
		setCurrentService(newService)
		window.scrollTo(0, currentService.scrollPosition);
	}

	return (
		<div className="App">
			<Route
				exact
				path="/"
				render={routeProps => (
					<HomePage
						{...routeProps}
						{...props}
						currentService={currentService}
						onChangeService={changeService}
					/>
				)}
			/>

			<Route
				path="/services"
				render={routeProps => (
					<ServicesPage
						{...routeProps}
						{...props}
						currentService={currentService}
						onChangeService={changeService}
					/>
				)}
			/>

			<Route
				path="/sponsorship"
				render={routeProps => <Sponsorship {...routeProps} {...props} />}
			/>

			<Route
				path="/contact"
				render={routeProps => <Contact {...routeProps} {...props} />}
			/>

			<Route
				path="/gallery"
				render={routeProps => <GalleryImages {...routeProps} {...props} />}
			/>

			<Route
				path="/testimonials"
				render={routeProps => <Testimonials {...routeProps} {...props} />}
			/>

			<Route
				path="/links"
				render={routeProps => <Links {...routeProps} {...props} />}
			/>

			{/*Main*/}
			<script src="js/jquery-1.11.3.min.js" />
			<script src="js/bootstrap.min.js" />
			<script src="js/modernizr.custom.js" />
			{/*Counter*/}
			<script src="assets/rendro-easy-pie-chart/dist/jquery.easypiechart.min.js" />
			<script src="js/waypoints.min.js" />
			<script src="js/jquery.easypiechart.min.js" />
			<script src="js/classie.js" />
			{/*Switcher*/}
			<script src="assets/switcher/js/switcher.js" />
			{/*Owl Carousel*/}
			<script src="assets/owl-carousel/owl.carousel.min.js" />
			{/*bxSlider*/}
			<script src="assets/bxslider/jquery.bxslider.min.js" />
			{/*jQuery UI Slider */}
			<script src="assets/slider/jquery.ui-slider.js" />
			{/*Isotope*/}
			<script src="assets/isotope/isotope.js" />
			{/*Slider*/}
			<script src="assets/slider/jquery.ui-slider.js" />
			{/*Fancybox*/}
			<script src="assets/fancybox/jquery.fancybox.pack.js" />
			{/*Theme*/}
			<script src="js/jquery.smooth-scroll.js" />
			<script src="js/wow.min.js" />
			<script src="js/jquery.placeholder.min.js" />
			<script src="js/theme.js" />
		</div>
	)
}

export default App
