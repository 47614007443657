import React from 'react';

import Header2 from '../Header2'
import Slider2 from '../Slider2'
import ContactPost from './ContactPost'
import Footer from '../../Footer';

const Contact = (props) => {
    return (
        <div>
            <Header2 {...props} />
            <Slider2 backgroundCss="status--contacts" sliderParentName="Home" sliderName="Contact us" />
            <ContactPost {...props} />
            <Footer {...props}/>
        </div>
    );
};

export default Contact;