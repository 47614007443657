import React from 'react';

import Header2 from '../Header2'
import Slider2 from '../Slider2'
import SponsorshipPost from './SponsorshipPost'
import Footer from '../../Footer';

const Sponsorship = (props) => {
    return (
        <div>
            <Header2 {...props} />
            <Slider2 backgroundCss="status--blog" sliderParentName="Home" sliderName="Sponsorship" />
            <SponsorshipPost />
            <Footer {...props}/>
        </div>
    );
};

export default Sponsorship;