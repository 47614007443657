import React from 'react';

const Synopsis = () => {
    return (
        <div>
        <section className="we-are we-are--home">
        <div className="container">
			<h2 className="title title--main wow fadeInUp" data-wow-delay="0.7s" 
			data-wow-duration="1.5s"><span className="title__bold">Your</span> Horsepower Specialists<span className="line line--title"><span className="line__first"></span>
			<span className="line__second"></span></span></h2>
			<br/>
			<p className="text text--anons">Thank you for taking the time to visit our corner of the motorcycle performance world.  </p>
			<p className="text text--anons">We have had a fascination for the motorcycle industry for the past twenty years and want to make this one the best yet. From the weekend track warrior to the dragracer to the street rider, we can cater to all of your 
								performance modification needs with little downtime.</p>
			<p className="text text--anons wow fadeInUp" data-wow-delay="0.7s" 
			data-wow-duration="1.5s">Getting you back on your bike instead of having it wait in a shop is our priority.
			From general maintenance (like tire installations and oil changes) to aftermarket accessories, we will look after you.</p>
			<p className="text text--anons wow fadeInUp" data-wow-delay="0.7s" 
			data-wow-duration="1.5s">We are also now involved in making custom CAD/CAM designed Hybrid Racing performance parts and accessories for the 
			individual looking for the extra edge in mind. We will be sponsoring privateer racers this year and will be there to help them make their 
			life easier at the track with product and tech support, so they can concentrate on their racing. </p>
			<p className="text text--anons wow fadeInUp" data-wow-delay="0.7s" 
			data-wow-duration="1.5s">Let us be your speed coordinators and enjoy browsing the site!</p>
        </div>
    </section>


        </div>

    );
};

export default Synopsis;