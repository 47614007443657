import React, { useState } from 'react';
import Gallery from 'react-photo-gallery';
import Lightbox from 'react-images';

import Header2 from '../Header2'
import Slider2 from '../Slider2'
import Footer from '../../Footer';

import * as myPhotos from './photosData'

const events = [
    {
        name: "SANAIR July 9th",
        photos: myPhotos.sanairPhotos,
    },{
        name: "Luskville July 30th",
        photos: myPhotos.luskvillePhotos,
    },{
        name: "Shannoville June 27th",
        photos: myPhotos.shanPhotos,
    },{
        name: "Total Sport Compact",
        photos: myPhotos.tscPhotos,
    },{
        name: "StarBoyz Stuntfest",
        photos: myPhotos.starboyzPhotos,
    },{
        name: "ImportNite - Lansdowne",
        photos: myPhotos.importPhotos,
    },{
        name: "Grudgedogz June 9th",
        photos: myPhotos.grudgedogzPhotos,
    },{
        name: "Trip to Lebanon",
        photos: myPhotos.lebanonPhotos,
    }
]

export default function GalleryImages(props) {
    const [currentImage, setCurrentImage] = useState(0)
    const [currentEvent, setCurrentEvent] = useState({
        name: "SANAIR July 9th",
        photos: myPhotos.sanairPhotos
    })
    const [lightboxIsOpen, setLightboxIsOpen] = useState(false)

    const openLightbox = (e, obj) => {
        setCurrentImage(obj.index)
        setLightboxIsOpen(true)
    }

    const closeLightbox = () => {
        setCurrentImage(0)
        setLightboxIsOpen(false)
    }

    const gotoPrevious = () => {
        setCurrentImage(currentImage - 1)
    }

    const gotoNext = () => {
        setCurrentImage(currentImage + 1)
    }

    const changeEvent = (event) => {
        setCurrentEvent(event)
    }

    return (
        <div>
            <Header2 {...props} />
            <Slider2 backgroundCss="status--gallery" sliderParentName="Home" sliderName="Gallery" />
            <section className="shop">
                <div className="container">
                    <div className="row row--main-shop">
                        <div className="col-lg-3 col-md-4 col-sm-5 col-xs-6">
                            <aside className="shop-aside">
                                <div className="shop-aside__block wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                    <h2 className="shop-title">Categories<span className="line line--title line--shop"><span className="line__first"></span><span className="line__second"></span></span></h2>
                                    <ul className="triangle-list triangle-list--shop">
                                        {events.map((event, index) => <li key={index} className="triangle triangle-list__one"><a href={`#${event.name}`} className="no-decoration" onClick={() => {
                                            changeEvent(event)
                                        }}>{event.name}</a></li>)}
                                    </ul>
                                </div>
                            </aside>
                        </div>
                        <div className="col-lg-9 col-md-8 col-sm-7 col-xs-6">
                            <div className="shop-main">
                                <header className="shop-main__head clearfix wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                    <h2 className="pull-left">{currentEvent.name}</h2>
                                </header>
                                <div className="items">
                                    <div className="row">
                                        <div className="col-xs-12 wow fadeInLeft" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                        <Gallery photos={currentEvent.photos} onClick={openLightbox} />
                                        <Lightbox images={currentEvent.photos}
                                            onClose={closeLightbox}
                                            onClickPrev={gotoPrevious}
                                            onClickNext={gotoNext}
                                            currentImage={currentImage}
                                            isOpen={lightboxIsOpen}
                                        />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <Footer {...props}/>
        </div>
    );
}
