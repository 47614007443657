import React from 'react';

const TestimonialsPost = (props) => {
    return (
        <div>
            <div className="blog-page">
			<div className="container">
				<div className="row">
					<div className="col-md-9 col-sm-8 col-xs-7">
						<div className="blog-main">
							<div className="articles">
								<div className="article">
                                <h3>From Customers All Over North America</h3>
                                    <h3> <a href="products/props_to_hybrid.rtf">Props to Hybrid (12kb)</a></h3>
								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-4 col-xs-5">
						<aside className="blog-aside">
						</aside>
					</div>
				</div>
			</div>
		</div>

        </div>
    );
};

export default TestimonialsPost;