import React from 'react';

const SponsorshipPost = (props) => {
    return (
        <div>
            <div className="blog-page">
			<div className="container">
				<div className="row">
					<div className="col-md-9 col-sm-8 col-xs-7">
						<div className="blog-main">
							<div className="articles">
								<div className="article">
									<div className="article__img wow slideInLeft" data-wow-delay="0.7s" data-wow-duration="1.5s">
										<img src="images/sponsorship/amadragbike.jpg" alt="amadrag" className="img-responsive" />
									</div>
									
									<h3 className="article-title wow slideInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">Hybrid Racing sponsors the AMA Prostreet Shootout</h3>

									<p className="blog-text blog-text--article wow slideInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">Hybrid Racing (Ottawa, ON) and the <span title="https://www.canadiandragbike.com/shootout"> <a title="https://www.canadiandragbike.com/shootout" href="https://www.canadiandragbike.com/shootout"> Suzuki Canadian Fastest Streetbike Shootout</a></span> have teamed up to offer up a bonus to the quickest qualified Canadian racer at the Grand Bend Motorplex event on June 20.</p>

									<p className="blog-text blog-text--article wow slideInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                    $100 cash will be awarded to the Canadian racer who posts the quickest elapsed time (E.T.) in qualifying. Three qualifying rounds are scheduled.</p>

                                    <p className="blog-text blog-text--article wow slideInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                    "I'm thrilled to partner with Jonathan Chen, Owner of Hybrid Racing   			for this bonus," stated Shootout Organizer Graeme Jones. "This is   			simply a way to show our support to the Canadian racers who have   			really stepped up their performance and made Pro Street racing a big   			deal here in Canada."</p>

                                    <p className="blog-text blog-text--article wow slideInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                    The Suzuki Canadian Fastest Streetbike Shootout will be held on   			Saturday, June 20 at Grand Bend Motorplex. Come see the best in   			Canada (including Hybrid Racing-sponsored Dustin Tellier) battle it   			out with some of the fastest Pro Street racers south of the border   			in a true Can-Am Shootout!</p>

                                    <div className="imagePost">
                                        <h4 className="" data-wow-delay="0.7s" data-wow-duration="1.5s">Dustin Tellier Turbo Hayabusa Canadian Prostreet Shootout</h4>
                                        <img src="images/sponsorship/dustin.jpg" alt="dustin" ></img>
                                    </div>

                                    <div className="imagePost">
                                        
                                        <h4 className="" data-wow-delay="0.7s" data-wow-duration="1.5s">Ellenbogen AM600 and AM Superbike #81</h4>
                                        <img src="images/sponsorship/efram4.jpg" alt="efram" ></img>
                                        <img src="images/sponsorship/efram5.jpg" alt="efram5" ></img>
                                    </div>

                                    <div className="imagePost">
                                        
                                    <h4 className="" data-wow-delay="0.7s" data-wow-duration="1.5s">Roxanne Gravelle Women's Cup Challenge Novice #332</h4>
                                    <img src="images/sponsorship/rox4.jpg" alt="rox" ></img>
                                    <img src="images/sponsorship/rox5.jpg" alt="rox5" ></img>
                                    </div>

                                    <div className="imagePost">
                                    <h4 className="" data-wow-delay="0.7s" data-wow-duration="1.5s">Joey Evans, Dirt Modified</h4>
                                    <img src="images/sponsorship/joey.jpg" alt="joey" ></img>
                                    <img src="images/sponsorship/joey2.jpg" alt="joey2" ></img>
                                    </div>


									<span className="line line--title line--blog-title line--article wow slideInLeft" data-wow-delay="0.7s" data-wow-duration="1.5s"><span className="line__first"></span><span className="line__second"></span></span>


								</div>
							</div>
						</div>
					</div>
					<div className="col-md-3 col-sm-4 col-xs-5">
						<aside className="blog-aside">
						</aside>
					</div>
				</div>
			</div>
		</div>

        </div>
    );
};

export default SponsorshipPost;