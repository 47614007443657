import React from 'react';

import Header2 from '../Header2'
import Slider2 from '../Slider2'
import Footer from '../../Footer';
import TestimonialsPost from './TestimonialsPost';

const Testimonials = (props) => {
    return (
        <div>
            <Header2 {...props} />
            <Slider2 backgroundCss="status--shop" sliderParentName="Home" sliderName="Testimonials" />
            <TestimonialsPost />
            <Footer {...props}/>
        </div>
    );
};

export default Testimonials;