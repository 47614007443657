import React from 'react';

import Header2 from '../Header2';
import Slider2 from '../Slider2';
import Services2 from './Services2';
import Footer from '../../Footer';



export default function ServicesPage(props) {
        return (
            <div>
                <Header2 {...props} />
                <Slider2 backgroundCss="status--dark" sliderParentName="Services" sliderName={props.currentService.name} />
                <Services2 {...props} />
                <Footer {...props}/>
            </div>
        );
}
