import React from "react";
import {Link} from 'react-router-dom'

const Header = props => {
    return (
        <div>
            <nav id="header-top" className="top-nav top-nav--home">
			<div className="container">
				<div className="top-nav__main top-nav__main--home">
					<div className="row">
						<div className="col-md-3 col-xs-12">
							<Link to="/" className="logo logo--home">
								<img src="images/sprocket-transparent-logo.png" alt="logo" />
								<h2 className="logo__title">
									HYBRID<span>Racing</span>
								</h2>
							</Link>
						</div>
						<div className="col-md-9 col-xs-12">
							<div className="main-nav main-nav--home navbar-main-slide">
								<ul className="" id="nav">
									<li><Link className="no-decoration" to="/">Home</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/services">Services</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/sponsorship">Sponsorship</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/gallery">Gallery</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/testimonials">Testimonials</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/contact">Contact Us</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/links">Links</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
        </div>
    );
};

export default Header;
