import React from 'react';

const Sliders = (props) => {
    return (
		<div>
        <section className="sliders sliders--home">
			<div className="container">
				<div className="row">
					<div className="col-xs-12">
						<ul className="sliders__main" data-auto="true" data-auto-hover="true" data-mode="fade" data-pager="false" data-pager-custom="null" data-prev-selector="null" data-next-selector="null">
							<li>
								{/* <img src="media/sliders/slider2.jpg" alt="bxSlider" /> */}
								<div className="sliders__text">
									<img src="images/hybridlogo-tran.png" alt="logo"/>
									<h2>Ottawa's Choice for</h2>
									<h3>Sport Bike Customization<span className="line line--slider"><span className="line__first"></span><span className="line__second"></span></span></h3>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</section>
		
	</div>
    );
};

export default Sliders;