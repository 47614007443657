import React from 'react';
import PropTypes from 'prop-types';



const Slider2 = (props) => {
    return (
		<section className={`status ${props.backgroundCss} dark-bg dark-bg--shop`}>
			<div className="container wow slideInRight" data-wow-delay="0.7s" data-wow-duration="1.5s">
				<div className="breadcumb">
					<span className="breadcumb__page no-decoration">{props.sliderParentName}</span>
					<span className="breadcumb__del no-decoration">&raquo;</span>
					<span className="breadcumb__page no-decoration">{props.sliderName}</span>
				</div>
				<h2 className="title title--page"><span className="title__bold">{props.sliderName}</span></h2>
			</div>
		</section>
    );
};

Slider2.propTypes = {
	backgroundCss: PropTypes.string.isRequired,
	sliderParentName: PropTypes.string,
	sliderName: PropTypes.string
};

Slider2.defaultProps = {
	sliderParentName: 'Home',
	sliderName: ''
};


export default Slider2;