import React from 'react';
import {Link} from 'react-router-dom'

const Partners = (props) => {
    return (
        <div>
    		<section className='info-blocks'>
			<div className="container">
				<div className="row">
					<div className="col-xs-6 col-xs-offset-1 wow fadeInLeft" data-wow-delay="0.7s" data-wow-duration="1.5s">
						<div className="info-block">
							<h3 className="title title--block"><span className="title__bold">Your</span> Canadian Distributor For</h3>
                            <img src="images/exotic.jpg" alt="exoticycle"/>
                            <h3 className=" wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">Performance Motorcycle Products</h3>
							<Link className="btn button button--red button--main" to={{
                                    pathname: "/services",
                                }} onClick={() => {
                                    props.onChangeService({name: "Parts Replacement",
									link: "parts-replacement"})
                                }}>See Parts</Link>
						</div>
					</div>
					<div className="col-xs-5  wow fadeInRight" data-wow-delay="0.7s" data-wow-duration="1.5s">
						<div className="info-block">
                            <div>
                                
                            <img src="images/hybrid-med.jpg" alt="hybrid racing"/> <strong>AND </strong>
                                <img src="images/techspec-med.jpg" alt="techspec"/><strong> HAVE 
                                    JOINED FORCES!</strong><br/><br/>
                                    <img src="images/dragpad.jpg" alt="dragpad"/>
                            </div>  
						</div>
					</div>
				</div>
			</div>
		</section>

</div>


    );
};

export default Partners;