import React from 'react';

const Footer = (props) => {
    return (
        <footer className="home-footer">
            <div className="footer-main-home dark-bg">
				<div className="container">
					<div className="row">
						<div className="col-md-12 col-xs-12 wow slideInRight text-center" data-wow-delay="0.7s" data-wow-duration="2.5s">
							<div className="footer-main-home__block equal-height-item">


								<h2 className="footer-main-home__title">Contact US</h2>
								<div className="phone">Phone: {props.phoneNum}</div>
								<div className="email"><span>Email: </span><a href="mailto:hybridracingca@hotmail.com"><img src='/images/hybrid.gif' alt='hybridracingca@hotmail.com'/></a></div>
								<div className="facebook"><a href="https://www.facebook.com/Hybrid-Racing-128495827208552"><img src="images/fb.jpg" alt="facebook" /></a></div>
								
							</div>
						</div>
					</div>
				</div>
			</div>

            <div className="footer-bottom-home">
				<div  className="container">
					<div className="copyright wow fadeInUp" data-wow-delay="0.7s" data-wow-duration="1.5s">
						<p>&copy; Copyright 2000-{props.copyrightYear} | <span>Hybrid Racing. </span> All rights reserved.</p>
					</div>
				</div>
			</div>
			<a id="to-top" href="#header-top"><i className="fa fa-chevron-up"></i></a>
		</footer>
    );
};

export default Footer;