import React from "react";
import {Link} from 'react-router-dom'

const Header = props => {
    return (
        <div>
            <header id="header-top" className="top-header">
                        <div className="container">
                            <div className="top-header__main wow slideInDown" data-wow-delay="0.7s" data-wow-duration="1.5s">
                                <div className="row">
                                    <div className="col-xs-4">
                                        <div className="social social--top clearfix">
                                            <a href="https://www.facebook.com/Hybrid-Racing-128495827208552" 
                                                className="social__one square">
                                                <span className="fa fa-facebook"></span>
                                            </a>
                                        </div>
                                    </div>
                                    <div className="col-xs-8">
                                        <div className="header-contacts clearfix">
                                            <div className="header-contacts__one">
                                                <Link to="/contact" className="header-contacts__phone square">
                                                    <span className="fa fa-phone"></span>
                                                </Link>
                                                <span className="header-contacts__link">{props.phoneNum}</span>
                                            </div>
                                            <div className="header-contacts__one">
                                                <a href="mailto:hybridracingca@hotmail.com" className="header-contacts__email square">
                                                    <span className="fa fa-envelope"></span>
                                                </a>
                                                <a href="mailto:hybridracingca@hotmail.com"><img src='/images/hybrid.gif' alt='hybridracingca@hotmail.com'/></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </header>

		<nav className="top-nav">
			<div className="container">
				<div className="top-nav__main">
					<div className="row">
						<div className="col-md-3 col-xs-12">
							<Link to="/" className="logo logo--home">
                                    <img src="images/sprocket-transparent-logo.png" alt="logo" />
								<h2 className="logo__title logo__title_black">
									Hybrid<span>Racing</span>
								</h2>
							</Link>
						</div>
						<div className="col-md-9 col-xs-12">
							<div className="main-nav navbar-main-slide">
								<ul className=" navbar-nav-menu" id="nav">
                                    <li><Link className="no-decoration" to="/">Home</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/services">Services</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/sponsorship">Sponsorship</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/gallery">Gallery</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/testimonials">Testimonials</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/contact">Contact Us</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
									<li><Link className="no-decoration" to="/links">Links</Link><span className="main-nav__separator"><span></span><span></span><span></span></span></li>
								</ul>
							</div>
						</div>
					</div>
				</div>
			</div>
		</nav>
        </div>
    );
};

export default Header;
