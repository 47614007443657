export const luskvillePhotos = [
    { src: 'images/gallery/lusk2/lusk1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lusk2/lusk3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/lusk2/lusk5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/lusk2/lusk6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk9.jpg', width: 4, height: 3 },

    { src: 'images/gallery/lusk2/lusk10.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk11.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lusk2/lusk12.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk13.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk14.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk16.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk17.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk18.jpg', width: 4, height: 3 },

    { src: 'images/gallery/lusk2/lusk19.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk20.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lusk2/lusk21.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk22.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk23.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk24.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk25.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk26.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk27.jpg', width: 4, height: 3 },

    { src: 'images/gallery/lusk2/lusk28.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk29.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lusk2/lusk30.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk31.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk32.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk33.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk34.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk35.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk36.jpg', width: 4, height: 3 },

    { src: 'images/gallery/lusk2/lusk37.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk38.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lusk2/lusk39.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk40.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk41.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk42.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk43.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk44.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk45.jpg', width: 4, height: 3 },

    { src: 'images/gallery/lusk2/lusk46.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk47.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lusk2/lusk48.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk49.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk50.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk51.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk52.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk53.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk54.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk55.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lusk2/lusk56.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lusk2/lusk57.jpg', width: 4, height: 3 },
];

export const sanairPhotos = [
    { src: 'images/gallery/sanair/san1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/sanair/san2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/sanair/san3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/sanair/san4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/sanair/san5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/sanair/san6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/sanair/san7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/sanair/san8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/sanair/san9.jpg', width: 4, height: 3 },

    { src: 'images/gallery/sanair/san10.jpg', width: 4, height: 3 },
    { src: 'images/gallery/sanair/san11.jpg', width: 1, height: 1 },
    { src: 'images/gallery/sanair/san12.jpg', width: 3, height: 4 },
    { src: 'images/gallery/sanair/san13.jpg', width: 3, height: 2 },
    { src: 'images/gallery/sanair/san14.jpg', width: 3, height: 2 },
    { src: 'images/gallery/sanair/san15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/sanair/san16.jpg', width: 3, height: 4 },
];

export const shanPhotos = [
    { src: 'images/gallery/shan/s1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/shan/s2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/shan/s3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/shan/s4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/shan/s5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/shan/s6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/shan/s7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/shan/s8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/shan/s9.jpg', width: 4, height: 3 },

    { src: 'images/gallery/shan/s10.jpg', width: 4, height: 3 },
    { src: 'images/gallery/shan/s11.jpg', width: 1, height: 1 },
    { src: 'images/gallery/shan/s12.jpg', width: 3, height: 4 },
    { src: 'images/gallery/shan/s13.jpg', width: 3, height: 2 },
    { src: 'images/gallery/shan/s14.jpg', width: 3, height: 2 },
    { src: 'images/gallery/shan/s15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/shan/s16.jpg', width: 3, height: 4 },
    { src: 'images/gallery/shan/s17.jpg', width: 4, height: 3 },
    { src: 'images/gallery/shan/s18.jpg', width: 4, height: 3 },

    { src: 'images/gallery/shan/s19.jpg', width: 3, height: 4 },
    { src: 'images/gallery/shan/s20.jpg', width: 3, height: 2 },
    { src: 'images/gallery/shan/s21.jpg', width: 3, height: 2 },
    { src: 'images/gallery/shan/s22.jpg', width: 4, height: 3 },
];

export const tscPhotos = [
    { src: 'images/gallery/tsc/tsc1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/tsc/tsc3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc9.jpg', width: 4, height: 3 },

    { src: 'images/gallery/tsc/tsc10.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc11.jpg', width: 1, height: 1 },
    { src: 'images/gallery/tsc/tsc12.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc13.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc14.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc16.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc17.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc18.jpg', width: 4, height: 3 },

    { src: 'images/gallery/tsc/tsc19.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc20.jpg', width: 1, height: 1 },
    { src: 'images/gallery/tsc/tsc21.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc22.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc23.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc24.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc25.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc26.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc27.jpg', width: 4, height: 3 },

    { src: 'images/gallery/tsc/tsc28.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc29.jpg', width: 1, height: 1 },
    { src: 'images/gallery/tsc/tsc30.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc31.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc32.jpg', width: 3, height: 2 },
    { src: 'images/gallery/tsc/tsc33.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc34.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc35.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc36.jpg', width: 4, height: 3 },

    { src: 'images/gallery/tsc/tsc37.jpg', width: 4, height: 3 },
    { src: 'images/gallery/tsc/tsc38.jpg', width: 1, height: 1 },
    { src: 'images/gallery/tsc/tsc39.jpg', width: 3, height: 4 },
    { src: 'images/gallery/tsc/tsc40.jpg', width: 3, height: 2 },
];

export const starboyzPhotos = [
    { src: 'images/gallery/stunt/stunt1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/stunt/stunt3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt9.jpg', width: 4, height: 3 },

    { src: 'images/gallery/stunt/stunt10.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt11.jpg', width: 1, height: 1 },
    { src: 'images/gallery/stunt/stunt12.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt13.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt14.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt16.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt17.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt18.jpg', width: 4, height: 3 },

    { src: 'images/gallery/stunt/stunt19.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt20.jpg', width: 1, height: 1 },
    { src: 'images/gallery/stunt/stunt21.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt22.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt23.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt24.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt25.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt26.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt27.jpg', width: 4, height: 3 },

    { src: 'images/gallery/stunt/stunt28.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt29.jpg', width: 1, height: 1 },
    { src: 'images/gallery/stunt/stunt30.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt31.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt32.jpg', width: 3, height: 2 },
    { src: 'images/gallery/stunt/stunt33.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt34.jpg', width: 3, height: 4 },
    { src: 'images/gallery/stunt/stunt35.jpg', width: 4, height: 3 },
    { src: 'images/gallery/stunt/stunt36.jpg', width: 4, height: 3 },
];

export const importPhotos = [
    { src: 'images/gallery/import/import1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/import/import3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import9.jpg', width: 4, height: 3 },

    { src: 'images/gallery/import/import10.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import11.jpg', width: 1, height: 1 },
    { src: 'images/gallery/import/import12.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import13.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import14.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import16.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import17.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import18.jpg', width: 4, height: 3 },

    { src: 'images/gallery/import/import19.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import20.jpg', width: 1, height: 1 },
    { src: 'images/gallery/import/import21.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import22.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import23.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import24.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import25.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import26.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import27.jpg', width: 4, height: 3 },

    { src: 'images/gallery/import/import28.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import29.jpg', width: 1, height: 1 },
    { src: 'images/gallery/import/import30.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import31.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import32.jpg', width: 3, height: 2 },
    { src: 'images/gallery/import/import33.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import34.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import35.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import36.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import37.jpg', width: 3, height: 4 },
    { src: 'images/gallery/import/import38.jpg', width: 4, height: 3 },
    { src: 'images/gallery/import/import39.jpg', width: 4, height: 3 },
];

export const grudgedogzPhotos = [
    { src: 'images/gallery/grudge/grudge1.jpg', width: 4, height: 3 },
    { src: 'images/gallery/grudge/grudge9.jpg', width: 1, height: 1 },
    { src: 'images/gallery/grudge/grudge3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/grudge/grudge4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/grudge/grudge5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/grudge/grudge6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/grudge/grudge7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/grudge/grudge8.jpg', width: 4, height: 3 },
    { src: 'images/gallery/grudge/grudge10.jpg', width: 4, height: 3 },
];

export const lebanonPhotos = [
    { src: 'images/gallery/lebanon/lg/15.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lebanon/lg/2.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lebanon/lg/3.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lebanon/lg/4.jpg', width: 3, height: 2 },
    { src: 'images/gallery/lebanon/lg/5.jpg', width: 3, height: 2 },
    { src: 'images/gallery/lebanon/lg/6.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lebanon/lg/7.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lebanon/lg/8.jpg', width: 4, height: 3 },

    { src: 'images/gallery/lebanon/jay2.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lebanon/lg/10.jpg', width: 1, height: 1 },
    { src: 'images/gallery/lebanon/lg/11.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lebanon/lg/12.jpg', width: 3, height: 2 },
    { src: 'images/gallery/lebanon/friendsbikes.jpg', width: 3, height: 2 },
    { src: 'images/gallery/lebanon/jack-on-bike.jpg', width: 4, height: 3 },
    { src: 'images/gallery/lebanon/jcontlr.jpg', width: 3, height: 4 },
    { src: 'images/gallery/lebanon/jay1.jpg', width: 4, height: 3 },
];